






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import { labelTypes, tags } from "@/constants/products";
import makeSelectOptions from "@common/util/makeSelectOptions";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Products Management",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Name",
              sortable: true,
            },
            description: {
              text: "Description",
              sortable: true,
            },
            sku: {
              text: "SKU",
              sortable: true,
            },
            syncedSku: {
              text: "Synced SKU",
              sortable: true,
            },
            fulfillmentPriority: {
              text: "Fulfillment Priority",
              sortable: true,
            },
            fulfillmentWidth: {
              text: "Fulfillment Width",
              sortable: true,
            },
            fulfillmentHeight: {
              text: "Fulfillment Height",
              sortable: true,
            },
            weight: {
              text: "Weight",
              sortable: true,
            },
            tags: {
              text: "Tags",
              sortable: true,
              options: {
                labels: true,
              },
            },
            labelName: {
              text: "Label Name",
              sortable: true,
            },
            labelPrice: {
              text: "Label Price",
              sortable: true,
            },
            labelTypes: {
              text: "Label Types",
              sortable: true,
              options: {
                labels(value) {
                  return value?.map((item) => labelTypes[item]) || [];
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["admin"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "800px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              productVariants: {
                content: {
                  icon: "mdi-cube-outline",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    self.$router.push(`/app/products/${item._id}/product_variants`);
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Product Variants",
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("products", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("products", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "products",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("products", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              sku: {
                attrs: {
                  label: "SKU",
                },
              },
              syncedSku: {
                attrs: {
                  label: "Synced SKU",
                },
              },
              properties: {
                type: "XArrayInput",
                attrs: {
                  label: "Properties",
                  xOptions: {
                    content: {
                      itemLabel: (item) => item.label || item.key || "[Empty]",
                      itemProperties: {
                        key: {
                          attrs: {
                            required: true,
                            label: "Key",
                            autofocus: true,
                          },
                        },
                        label: {
                          attrs: {
                            label: "Label",
                          },
                        },
                        syncedKey: {
                          attrs: {
                            label: "Synced Key",
                          },
                        },
                        selections: {
                          type: "comboBoxMultiple",
                          attrs: {
                            label: "Selections",
                          },
                        },
                      },
                    },
                  },
                },
              },
              fulfillmentPriority: {
                type: "number",
              },
              fulfillmentWidth: {
                type: "number",
              },
              fulfillmentHeight: {
                type: "number",
              },
              weight: {
                type: "number",
                attrs: {
                  min: 0,
                  onkeyup: "if(value<0) value=0;",
                },
              },
              tags: {
                type: "selectMultiple",
                attrs: {
                  items: tags.map((item) => ({
                    value: item,
                    text: item,
                  })),
                },
              },
              labelName: {
                attrs: {
                  label: "Label Name",
                  required: true,
                },
              },
              labelPrice: {
                attrs: {
                  label: "Label Price (EUR)",
                  required: true,
                },
                ext: {
                  defaultValue: 5,
                },
              },
              labelTypes: {
                type: "selectMultiple",
                attrs: {
                  items: makeSelectOptions(labelTypes),
                  required: true,
                },
                ext: {
                  defaultValue: ["6"],
                },
              },
              designPrintBatchLimit: {
                type: "number",
                attrs: {
                  label: "Design Print Batch Limit",
                },
              },
            },
            insertForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "sku",
                  "syncedSku",
                  "properties",
                  "fulfillmentPriority",
                  "fulfillmentWidth",
                  "fulfillmentHeight",
                  "weight",
                  "tags",
                  "labelName",
                  "labelPrice",
                  "labelTypes",
                  "designPrintBatchLimit",
                ],
              },
            },
            editForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "sku",
                  "syncedSku",
                  "properties",
                  "fulfillmentPriority",
                  "fulfillmentWidth",
                  "fulfillmentHeight",
                  "weight",
                  "tags",
                  "labelName",
                  "labelPrice",
                  "labelTypes",
                  "designPrintBatchLimit",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
